#hero-area {
  background: url('../images/bankkarza_home_pic.jpg') no-repeat 50%;
  background-size: cover;
  background-attachment: fixed;
  padding: 230px 0;
  color: $white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  position: relative;
  &:before {
    content: '';
    z-index: 9;
    background: rgba(255, 255, 255, 0.78);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .block {
    position: relative;
    z-index: 999;
  }
  h1 {
    font-size: 40px;
    line-height: 50px;
    color: #333;
    font-weight: 700;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  h2 {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 38px;
    line-height: 27px;
    text-transform: uppercase;
    color: #666;
    font-family: 'Roboto', sans-serif;
    margin-top: 25px;
  }
  .btn {
    background: #414141;
    border: none;
    color: $white;
    padding: 20px 35px;
    margin-top: 30px;
    font-size: 16px;
    font-size: 13px;
    line-height: 1em;
    text-transform: uppercase;
    letter-spacing: normal;
    border-radius: 0;
  }
}
